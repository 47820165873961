<template>
	<v-container fluid fill-height>
		<v-row align="center" justify="center" no-gutters>
			<v-col cols="12">
				<base-heading :title="title">
        </base-heading>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12">
				<v-form ref="SearchForm">
					<v-row justify="center">
						<v-col cols="7" sm="4" md="4" lg="4" xl="3">
							<base-number-field fullWidth v-model="editedSearchItem.purchaseId" label="Nro de Compra" maxlength="25"
								:rules="[validationRules.purchaseIdRules.counter]" 
								required
							></base-number-field>
						</v-col>
						<v-col align="center" cols="4" sm="2" md="2" lg="2" xl="2" >
							<base-btn dark color="teal" class="mt-2" @click="getSummary()" icon="mdi-table-search" btnText="Buscar" :loading="loadingSearchForm"></base-btn>
						</v-col>
					</v-row>
				</v-form>
			</v-col>

			<v-col cols="12">
				<base-form-simple
					formTitle="Datos del crédito"
					:formLoading="loadingCreditForm"
					hideLabelRequiredFields
					hideDefaultActions
				>
					<template v-slot:formContent>
						<v-row>
							<v-col cols="12" sm="6" md="6">
								<base-text-field :value="editedItem.branchOffice" label="Sucursal" placeholder="Casa Matriz" readonly></base-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="6">
								<base-text-field :value="editedItem.purchaseId" label="Nro. de Compra" placeholder="0000" readonly></base-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="6">
								<base-text-field :value="editedItem.provider" label="Proveedor" placeholder="Nombre" readonly></base-text-field>
							</v-col>
							<v-col cols="12" sm="3" md="3">
								<base-text-field :value="editedItem.totalPrice" label="Precio Total" placeholder="0" readonly></base-text-field>
							</v-col>
							<v-col cols="12" sm="3" md="3">
								<base-text-field :value="editedItem.status" label="Estado" placeholder="Pendiente" readonly></base-text-field>
							</v-col>
						</v-row>
					</template>

					<template v-if="editedItem.purchaseId" v-slot:customFormActions>
						<base-btn enableSmallBtn color="secondary" @click="reloadData()" icon="mdi-reload" btnText="Actualizar" :loading="loadingCreditForm"></base-btn>
						<v-spacer></v-spacer>
						<base-btn dark enableSmallBtn color="green darken-1" @click="getPaymentStatement()" icon="mdi-file-chart" btnText="Extracto"></base-btn>
						<v-spacer></v-spacer>
						<base-btn dark enableSmallBtn color="red darken-1" @click="cleanData()" icon="mdi-close-thick" btnText="Limpiar"></base-btn>
					</template>
				</base-form-simple>
			</v-col>

			<v-col cols="12" :sm="showPaymentForm?'6':'12'">
				<base-form-container v-if="editedItem.purchaseId"
					title="Pagos"
					titleClasses="primary white--text"
					:formLoading="loadingCreditForm"
				>
					<template v-slot:content>
						<v-row>
							<v-col cols="12">
								<v-timeline
									:dense="$vuetify.breakpoint.smAndDown"
									clipped
								>
								<v-slide-x-transition
									group
									hide-on-leave
								>
									<v-timeline-item
										v-for="payment in editedItem.creditPayments"
										:key="payment.id"
										icon="mdi-cash"
										fill-dot
									>
										<span slot="opposite">Registrado<br>{{payment.paymentDate}}</span>
										<v-card class="elevation-2">
											<v-card-title>
												<template v-if="payment.id === 1">
													Pago Inicial
												</template>
												<template v-else-if="payment.remainingAmount == 0.00 || payment.remainingAmount == 0">
													Pago Final
												</template>
												<template v-else>
													Pago Nro {{payment.id}}	
												</template>
											</v-card-title>
											<v-card-text>
												<template v-if="$vuetify.breakpoint.smAndDown">
													Fecha: {{payment.paymentDate}}
													<br>
												</template>
												Tipo de Pago: {{payment.paymentType}}
												<br>
												Monto: Bs.{{payment.amountPaid}}
												<br>
												Saldo: Bs.{{payment.remainingAmount}}
												<template v-if="payment.remainingAmount != 0.00 && payment.remainingAmount != 0">
													<br>
													Sig. Pago: {{payment.nextPaymentDate}}	
												</template>
											</v-card-text>
										</v-card>
									</v-timeline-item>
								</v-slide-x-transition>
								</v-timeline>
							</v-col>
						</v-row>
					</template>
				</base-form-container>
			</v-col>

			<v-col cols="12" sm="6">
				<base-form-simple v-if="showPaymentForm"
					ref="PaymentForm"
					formTitle="Registrar Pago"
					titleClasses="secondary white--text"
					:formLoading="loadingPaymentForm"
					hideCancelButton
					@saveButton="savePayment"
					fixed
				>
					<template v-slot:formContent>
						<v-row align="center" justify="center" no-gutters>
							<v-col cols="6" align="center">
								<base-label text="*Tipo de pago">
									<v-radio-group class="mt-0 pt-0"
										v-model="editedPaymentItem.paymentTypeId"
										mandatory
										row
										@change="editedPaymentItem.cardNumber=''"
									>
										<v-radio color="info"
											label="Efectivo"
											value="1"
										></v-radio>
										<v-radio color="info"
											label="Tarjeta"
											value="2"
										></v-radio>
									</v-radio-group>
								</base-label>
							</v-col>
							<v-col cols="6" align="center">
								<base-currency-field 
									label="Monto"
									v-model="editedPaymentItem.amountPaid"
									:rules="[validationRules.amountPaidRules.validAmount, validationRules.amountPaidRules.verify]" 
									required
								></base-currency-field>
							</v-col>

							<v-col v-if="totalRemainingAmount > 0" cols="6" align="center" class="mb-4">
								<base-date-picker-dialog 
									label="Próximo Pago" 
									only-later-dates  
									v-model="editedPaymentItem.nextPaymentDate"
									:rules="[commonValRules.required]" 
									required
								></base-date-picker-dialog>
							</v-col>
							<v-col cols="6" align="center" class="mb-4">
								<h3>
									<strong>Saldo</strong><p class="mt-2 mb-0">Bs. {{totalRemainingAmount}}</p>
								</h3>
							</v-col>

							<v-expand-transition>
								<v-col v-if="totalRemainingAmount < 0" cols="12">
									<v-alert
										text
										elevation="2"	
										color="red"
									>
										<v-icon color="red" left>mdi-alert-circle</v-icon>
										<strong>El monto a pagar no debe ser mayor al saldo.</strong>
									</v-alert>
								</v-col>
							</v-expand-transition>

							<v-expand-transition>
								<v-col v-if="showCreditCardNumber" cols="12" sm="8" align="center" class="mb-4">
									<base-card-number-field 
										v-model="editedPaymentItem.cardNumber"
								 		required masked
									></base-card-number-field>
								</v-col>
							</v-expand-transition>
						</v-row>
					</template>
				</base-form-simple>
			</v-col>
		</v-row>

		<base-pdf-viewer :show="showPdfViewer" :title="reportFile.title" :src="reportFile.fileContent" :fileName="reportFile.fileName" @close="closeViewer()"/>
	</v-container>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
	mixins: [MainExternalData],

	data() {
		return {
			title: 'Créditos de Compra',
			editedSearchItem: {
				purchaseId:''
			},
			defaultSearchItem: {
				purchaseId:''
			},
			loadingSearchForm: false,
			
			editedItem: {
				branchOffice: '',
				purchaseId:'',
				provider:'',
				totalPrice: '',
				remainingAmount: '',
				statusId: 0,
				status:'',
				creditPayments:[]
      },
      defaultItem: {
				branchOffice: '',
				purchaseId:'',
				provider:'',
				totalPrice: '',
				remainingAmount: '',
				statusId: 0,
				status:'',
				creditPayments:[]
			},
			loadingCreditForm: false,

			editedPaymentItem: {
				paymentTypeId: 1,
				amountPaid: '',
				cardNumber: '',
				nextPaymentDate: ''
      },
			defaultPaymentItem: {
				paymentTypeId: 1,
				amountPaid: '',
				cardNumber: '',
				nextPaymentDate: ''
      },
			loadingPaymentForm: false,

			validationRules: {
				purchaseIdRules: {
          counter: value => (value != null && value.toString().length <= 25) || 'No debe tener más de 25 caracteres.',
				},
				amountPaidRules: {
					verify: value => (parseFloat(value) > 0) || 'Debe ser mayor a 0.',
					validAmount: value => (value != null && this.totalRemainingAmount >= 0) || 'No debe ser mayor al saldo.'
				},
			},

			//PDF comprobante
			showPdfViewer: false,
			reportFile:{
				title: '',
				fileName:'',
				fileContent:''
			},
			defaultReportFile:{
				title: '',
				fileName:'',
				fileContent:''
			},
		}
	},

	computed: {
		showPaymentForm() {
			return this.editedItem.statusId === 6;
		},

		showCreditCardNumber() {
			return this.editedPaymentItem.paymentTypeId == 2;
		},

		totalRemainingAmount() {
			const result = (this.editedItem.remainingAmount - this.editedPaymentItem.amountPaid).toFixed(2);
			return result;
		}
	},

	methods: {
		async getSummary() {
			if (!this.$refs.SearchForm.validate()) {
        return;
			}
			let me=this;
			try {
				me.SHOW_LOADING()
				me.loadingSearchForm=true;
				me.editedItem = Object.assign({}, me.defaultItem);
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'purchaseId': me.editedSearchItem.purchaseId
				};
				await me.getObjectResponse('api/CompanyPurchaseCredit/GetSummaryOfPayments', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.editedItem = Object.assign({}, data);
					me.editedItem.purchaseId = me.editedSearchItem.purchaseId;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.loadingSearchForm=false;
      }
		},

		async savePayment() {
			let me=this;
			try {
				me.SHOW_LOADING()
				me.loadingPaymentForm = true
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'purchaseId': me.editedItem.purchaseId,
					'paymentTypeId': parseInt(me.editedPaymentItem.paymentTypeId, 10),
					'amountPaid': parseFloat(me.editedPaymentItem.amountPaid),
					'cardNumber': me.editedPaymentItem.cardNumber,
					'nextPaymentDate': me.editedPaymentItem.nextPaymentDate,
				};
				await me.getObjectResponse('api/CompanyPurchaseCredit/AddNextPayment', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.editedItem.remainingAmount = data.remainingAmount;
					me.editedItem.statusId = data.statusId;
					me.editedItem.status = data.status;
					me.editedItem.creditPayments.push(data.creditPayment);
					me.editedPaymentItem = Object.assign({}, me.defaultPaymentItem);
					me.$refs.PaymentForm.reset();

					if (data.fileContent != null) {
						me.reportFile = Object.assign({}, data);
						me.reportFile.title = `Comprobante ${me.editedItem.purchaseId}`;
						me.showPdfViewer=true;
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
				me.catchError(error)
			} finally {
				me.HIDE_LOADING()
				me.loadingPaymentForm = false
			}
		},

		async reloadData() {
			let me=this;
			try {
				me.SHOW_LOADING()
				me.loadingCreditForm=true;
				const lastData = me.editedItem;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'purchaseId': me.editedItem.purchaseId
				};
				await me.getObjectResponse('api/CompanyPurchaseCredit/GetSummaryOfPayments', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.editedItem = Object.assign({}, data);
					me.editedItem.purchaseId = lastData.purchaseId;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.loadingCreditForm=false;
      }
		},

		cleanData() {
			let me=this;
			me.editedSearchItem = Object.assign({}, me.defaultSearchItem);
			me.editedItem = Object.assign({}, me.defaultItem);
			me.editedPaymentItem = Object.assign({}, me.defaultPaymentItem);
			me.$refs.SearchForm.reset();
		},

		async getPaymentStatement() {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'purchaseId': me.editedItem.purchaseId
				};
				await me.getObjectResponse('api/CompanyPurchaseCredit/GetPaymentStatement', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.reportFile = Object.assign({}, data);
					me.reportFile.title = `Extracto Compra ${me.editedItem.purchaseId}`;
					me.showPdfViewer=true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

		closeViewer() {
			this.reportFile = Object.assign({}, this.defaultReportFile);
			this.showPdfViewer = false;
		},
	},
}
</script>